import { Box, Card, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { SVG } from "../../icon/svg.file";
import { useTranslation } from "react-i18next";
import { getDateWithTimeFormat } from "../../utility-files/date-util/DateHandling";
import { isIntegerValue } from "../../utility-files/data-util/DataHandler";
import moment from "moment";
import action from "../../new redux/Action";
import { useDispatch } from "react-redux";
import constants from "../../new redux/Types/actionTypes";

export default function MobileTable({ rowData, status }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // console.log("<MobileTable> rowData", rowData);
  return (
    <>
      {Array.isArray(rowData) &&
        rowData?.map((index) => (
          <Card
            sx={{
              borderRadius: "8px",
              mb: 3,
              border: "1px solid #ccc",
              "& table": {
                "& th": {
                  background: "#493932",
                  padding: "10px",
                  borderBottom: "1px solid #ccc",
                  color: "#CBD5E0",
                  width: "50%",
                },
                "& td": {
                  padding: "10px",
                  borderBottom: "1px solid #ccc",
                  background: "#160B01",
                  color: "#fff",
                  width: "50%",
                },
              },
            }}
          >
            <table width="100%" cellPadding={"0"} cellSpacing={"0"} border={0}>
              <tr>
                <th>{t("Billboard_main.Billboards.tabs.billboard_address")}</th>
                <td> {index?.address || "No data found"}</td>
              </tr>
              <tr>
                <th>{t("Billboard_main.Billboards.tabs.owner")}</th>
                <td>{index.contactName || "No data found"}</td>
              </tr>
              {status ? null : (
                <tr>
                  <th>{t("Ad_index.commonTable.columns.status")}</th>
                  <td>
                    {index.status === "offline" ? (
                      <Box
                        component={"span"}
                        sx={{ color: "#F01F1F", fontWeight: "600" }}
                      >
                        Offline
                      </Box>
                    ) : (
                      <Box
                        component={"span"}
                        sx={{ color: "#349734", fontWeight: "600" }}
                      >
                        Online
                      </Box>
                    )}
                  </td>
                </tr>
              )}
              {status === "offline" ? (
                <>
                  {" "}
                  <tr>
                    <th>
                      {t("Billboard_main.Billboards.tabs.offline_started")}
                    </th>
                    <td>
                      {moment(index?.offlineStarted).format(
                        "DD-MMM-YYYY HH:MM"
                      ) || "No data found"}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      {t("Billboard_main.Billboards.tabs.linked_tickets")}
                    </th>
                    <td>
                      <Link
                        to={`/ticket?id=1&ticketTabs=1&billboardFilter=true`}
                        onClick={() =>
                          dispatch(
                            action(
                              constants.billboardName,
                              index?.billboardName
                            )
                          )
                        }
                      >
                        {index?.linkedTickets || "No Data"}
                      </Link>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <th>{t("Billboard_main.Billboards.tabs.installation")}</th>
                    <td>
                      {getDateWithTimeFormat(
                        index.installationDate || "Invaliad Date"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>{t("Billboard_main.Billboards.tabs.ads_amount")}</th>
                    <td>{isIntegerValue(index.ADSAmount)}</td>
                  </tr>
                  <tr>
                    <th>{t("Billboard_main.Billboards.tabs.ads_exposures")}</th>
                    <td>
                      {(() => {
                        if (isIntegerValue(index.ADSAmount)) {
                          const lastSpot =
                            index?.spots_per_Ad_per_day[
                              index?.spots_per_Ad_per_day?.length - 1
                            ];
                          const lastSpotDate = moment(lastSpot?.date);
                          const yesterday = new Date();
                          yesterday.setDate(yesterday?.getDate() - 1);
                          /**
                           * Compare only date but not the time
                           */
                          if (moment(yesterday).isSame(lastSpotDate, "day")) {
                            // console.log("<BillBoard> tooltip index", index);
                            const toolTipTitle = index?.activeAds
                              ?.map(
                                (ad) =>
                                  `${ad?.title} - ${Math.round(
                                    ad?.totalAdexposure
                                  )} \t`
                              )
                              .join(" + ");
                            return (
                              <Tooltip title={toolTipTitle} arrow>
                                {index?.activeAds
                                  ? index?.activeAds.reduce(
                                      (acc, curr) =>
                                        acc + Math.round(curr?.totalAdexposure),
                                      0
                                    )
                                  : t(
                                      "Billboard_index.typography.no_data_available"
                                    )}
                              </Tooltip>
                            );
                          }
                        }
                        return 0;
                      })()}
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <th>{t("Ad_index.commonTable.columns.actions")}</th>
                <td>
                  <IconButton
                    component={Link}
                    to={`/billboard/details?id=1`}
                    disableRipple
                    sx={{
                      background: "#322E27",
                      borderRadius: "10px",
                      width: "30px",
                      height: "30px",
                    }}
                    onClick={() =>
                      dispatch(
                        action(constants.billboardName, index?.billboardName)
                      )
                    }
                  >
                    <SVG.EyeIcon />
                  </IconButton>
                </td>
              </tr>
            </table>
          </Card>
        ))}
    </>
  );
}
