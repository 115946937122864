import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SingleDatePiker from "../../components/chart/SingleDatePiker";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import * as global from "../../constant/global";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import { TicketType } from "./utll.data";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import * as StorageHandling from "../../utility-files/storage-util/StorageHandling";
import { IonNote } from "@ionic/react";
import moment from "moment";
import { stripHtmlTags } from "../../utility-files/helper-function/HelperFunction";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const USER_ROLE = Object.freeze({
  Partner: "Partner",
  Client: "Client",
  Employee: "Employee",
  Admin: "Admin",
  Advertiser: "Advertiser",
  SuperAdmin: "SuperAdmin",
  GuestUser: "GuestUser",
  LedproAdmin: "LedproAdmin",
});

export default function Add({ setOpenModal }) {
  const { t } = useTranslation();

  const createTicketSchema = Yup.object({
    billboard: Yup.string().required(
      t("Ticket_add.add_ticket.errors.billboard_name")
    ),
    ticket_name: Yup.string().required(
      t("Ticket_add.add_ticket.errors.ticket_name")
    ),
    ticket_type: Yup.mixed().required(
      t("Ticket_add.add_ticket.errors.ticket_type")
    ),
    ticket_sub_type: Yup.mixed().required(
      t("Ticket_add.add_ticket.errors.ticket_subtype")
    ),
    ticket_request: Yup.string().optional(),
    description: Yup.string().required(
      t("Ticket_add.add_ticket.errors.description")
    ),
    sub_description: Yup.string().optional(),
    closing_at: Yup.date().nullable().optional(),
  });
  const [value, setValue] = useState("");
  const [billboard, setBillboard] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [ticketSubType, setTicketSubType] = React.useState([]);
  const [selectedItemsFollowers, setSelectedItemsFollowers] = useState([
    "Ticket Followers 1",
  ]);
  const [listOfEmployees, setListOfEmployees] = React.useState([]);
  const [TicketTypes, setTicketTypes] = React.useState(
    Object.keys(TicketType).map((type) => ({
      name: TicketType[type],
      value: type,
    }))
  );
  const [query, setQuery] = useSearchParams();
  const userData = useSelector((state) => state.UserData);
  const loadBillboards = useSelector((state) => state.cameraBillBoard);
  const [selectedDate, setSelectedDate] = useState(null);
  const handleStartDateChange = (date) => {
    setSelectedDate(date);
  };

  const isAuthenticated =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);

  const billboards = userData.data?.doc?.billboards || [];

  const setTicket = (id, filter) => {
    query.set("filter", filter);
    query.set("ticket", id);
    setQuery(query);
  };

  const handleChangeSeleted = (event) => {
    setSelectedItems(event.target.value);
  };
  const handleChangeSeletedFollowers = (event) => {
    setSelectedItemsFollowers(event.target.value);
  };

  const getEmployees = async () => {
    // setPending(true);
    try {
      const request = getRequestForApi(
        global.ALL_EMPLOYEES_LIST +
          `&country=${userData.data.doc.address.country}`,
        methodType.GET
      );
      const response = await callHttpRequest(request).then(({ data }) => {
        if (data.data.docs) {
          setListOfEmployees(
            data.data.docs.map((i) => ({
              _id: i._id,
              email: i.email,
              fullname: i.fullname,
            }))
          );
        }
      });
    } catch (err) {
      console.error("Error fetching Employee list:", err);
    } finally {
      // console.log("+++++", listOfEmployees);
      // setPending(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      ticket_assignee: [],
      ticket_follower: [],
      description: "",
    },
    validationSchema: createTicketSchema,
    onSubmit: (values) => {
      console.log("<Add> submitting....", values);
      const updatedDescription = stripHtmlTags(values.description);
      const data = {
        ...values,
        description: updatedDescription,
        closing_at: selectedDate ? selectedDate : "",
      };
      axios
        .post(global.GET_TICKET_DETAILS, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then((data) => {
          if (
            !!data.data &&
            (userData.role === "Admin" || userData.role === "SuperAdmin")
          ) {
            axios
              .patch(
                global.APPROVE_TICKET + `${data.data.data._id}`,
                {},
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${isAuthenticated}`,
                  },
                }
              )
              .then(() => {
                formik.resetForm();
                formik.setSubmitting(false);
                let msg = `Ticket id: ${data.data.data._id.substr(
                  -5
                )} has been approved by ADMIN`;
                setTicket(data.data.data._id, "approve");
                // setTimeout(() => {
                //   sendMessage(data.data.data._id, msg);
                // }, 4000);

                toast.success(t("Ticket_index.common.statuses.approve"));
              });
          } else if (!!data.data) {
            formik.setSubmitting(false);
            setTicket(data.data.data._id, "unapprove");
            toast.success("Ticket Created Successfully !");
          } else {
            toast.error("this ticket name is already taken");
          }
          setOpenModal(false);
        })
        .catch(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const handleSubmitForm = (e) => {
    e.preventDefault();
    formik.setSubmitting(true);
    const followArray = formik.values.ticket_follower.map((item) => {
      return {
        type: "follower",
        user: item,
      };
    });

    const assigneArray = formik.values.ticket_assignee.map((item) => {
      return {
        type: "assignee",
        user: item,
      };
    });

    const uniqueAssignee = assigneArray.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj["user"]).indexOf(obj["user"]) === pos;
    });

    const uniqueFollower = followArray.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj["user"]).indexOf(obj["user"]) === pos;
    });

    const body = [...uniqueAssignee, ...uniqueFollower];
    formik.setValues({ ...formik.values, member: body });
    setTimeout(() => {
      formik.handleSubmit();
    }, 100);
  };

  const { errors } = formik;

  const handleChangeType = (e) => {
    if (e.target.value === "sales") {
      setTicketSubType([
        { name: "Payment", value: "payment" },
        { name: "Contract", value: "contract" },
        { name: "Other", value: "other" },
      ]);
    } else if (e.target.value === "maintenance") {
      setTicketSubType([
        { name: "Repair", value: "repair" },
        { name: "Ongoing", value: "ongoing" },
        { name: "Other", value: "other" },
      ]);
    } else if (e.target.value === "advertising") {
      setTicketSubType([
        { name: "Upload Ad", value: "upload_ad" },
        { name: "Remove Ad", value: "remove_ad" },
        { name: "Other", value: "other" },
      ]);
    } else if (e.target.value === "installation") {
      setTicketSubType([
        { name: "Installation", value: "installation" },
        { name: "Other", value: "other" },
      ]);
    } else {
      setTicketSubType([{ name: "Other", value: "other" }]);
    }
  };

  React.useEffect(() => {
    const getBillboard = async () => {
      //  let response = await GetallBillboard();
      //  if (response.remote === "success") {
      //  const self = (await GetSelf()).data.data.doc;
      const self = userData.data.doc;
      if (self.role === USER_ROLE.Employee) {
        setTicketTypes([
          {
            name: "Maintenance",
            value: "maintenance",
          },
          {
            name: "Advertising",
            value: "advertising",
          },
          {
            name: "Installation ",
            value: "installation",
          },
          {
            name: "Other",
            value: "other",
          },
        ]);
      } else if (self.role === USER_ROLE.Admin || self.role === "SuperAdmin") {
      } else if (self.role === USER_ROLE.Client) {
        setTicketTypes([
          {
            name: "Maintenance",
            value: "maintenance",
          },
          {
            name: "Advertising",
            value: "advertising",
          },
        ]);
      }
      //  } else {
      //    setTicketTypes([]);
      //  }
    };

    getBillboard();
    getEmployees();
  }, []);

  useEffect(() => {
    console.log("Formik values", formik.values);
  }, [formik.values]);

  const handleChange = (event) => {
    setBillboard(event.target.value);
  };

  const MenuProps = {
    PaperProps: {
      sx: {
        background: "#0E0E0E",
        "& .MuiList-root": {
          padding: "15px !important",
          color: "#fff",

          "& .MuiButtonBase-root": {
            background: "#282520",
            mb: 1,
            borderRadius: "5px",
          },
        },
      },
    },
  };

  const MenuAssignee = {
    PaperProps: {
      sx: {
        background: "#0E0E0E",
        "& .MuiList-root": {
          padding: "15px !important",
          color: "#fff",
          "& .MuiButtonBase-root": {
            px: 0,
          },
          "& .Mui-selected": {
            background: "transparent",
          },
          "& .MuiCheckbox-root": {
            padding: "0px",
            mr: 1.5,
            color: "#fff",
          },
        },
      },
    },
  };

  useEffect(() => {
    const selectedBillboard = loadBillboards.find(
      (loadBillboard) => formik.values.billboard === loadBillboard.billboardName
    );
    setBillboard(selectedBillboard?.address || "");
  }, [formik.values.billboard]);

  return (
    <>
      <form onSubmit={handleSubmitForm}>
        <Typography
          variant="h1"
          sx={{
            fontSize: "30px",
            fontWeight: "700",
            mb: 3,
            "@media(max-width:992px)": {
              fontSize: "24px",
            },
          }}
        >
          {t("Ticket_add.add_ticket.title")}
        </Typography>
        <Stack direction={"column"} spacing={2}>
          <Box
            sx={{
              "& label": {
                mb: 1,
                fontSize: "12px",
                display: "block",
              },
            }}
          >
            <label> {t("Ticket_add.add_ticket.labels.ticket_name")}</label>
            <TextField
              size="small"
              placeholder={t("Ticket_add.add_ticket.labels.ticket_name")}
              fullWidth
              value={formik.values.ticket_name}
              name="ticket_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{
                "& .MuiInputBase-root": {
                  background: "transparent !important",
                  borderRadius: "10px",
                  border: "1px solid #687588",
                  color: "#fff",
                  input: {
                    "&::placeholder": {
                      color: "#687588",
                      opacity: 1,
                      textAlign:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "right"
                          : "left",
                    },
                  },
                },
              }}
            />
            {errors.ticket_name && (
              <FormHelperText sx={{ color: "red" }}>
                {errors.ticket_name}
              </FormHelperText>
            )}
          </Box>

          <Box
            sx={{
              "& label": {
                mb: 1,
                fontSize: "12px",
                display: "block",
              },
            }}
          >
            <label>
              {t("Ticket_add.add_ticket.labels.ticket_description")}
            </label>
            <Box
              component={ReactQuill}
              theme="snow"
              value={formik.values.description}
              onChange={(content, delta, source, editor) => {
                formik.setFieldValue("description", editor.getHTML());
              }}
              onBlur={() => formik.setFieldTouched("description", true)}
              name="description"
              placeholder={t(
                "Ticket_add.add_ticket.placeholders.ticket_description"
              )}
              sx={{
                "& .ql-editor.ql-blank::before": {
                  color: "#687588",
                  fontStyle: "normal",
                  textAlign:
                    localStorage.getItem("i18nextLng") === "ar"
                      ? "right"
                      : "left",
                  fontFamily:
                    localStorage.getItem("i18nextLng") === "ar"
                      ? `"Cairo", sans-serif`
                      : `"Manrope", sans-serif`,
                },
                "& .ql-toolbar.ql-snow": {
                  border: "1px solid #687588",
                  borderRadius: "10px 10px 0px 0px",
                  textAlign:
                    localStorage.getItem("i18nextLng") === "ar"
                      ? "right"
                      : "left",
                  direction:
                    localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr",
                },
                "& .ql-snow .ql-picker.ql-header": {
                  width: localStorage.getItem("i18nextLng") === "ar" && "auto",
                },
                "& .ql-container.ql-snow": {
                  border: "1px solid #687588",
                  height: "180px",
                  borderRadius: "0px 0px 10px 10px",
                },
                "& .ql-snow .ql-picker": { color: "#fff" },
                "& .ql-snow .ql-stroke": { stroke: "#fff" },
                "& .ql-picker-options": {
                  background: "#0000004D",
                },
                "& .ql-selected": { color: "#fff !important" },
              }}
            />
            {errors.description && (
              <FormHelperText sx={{ color: "red" }}>
                {errors.description}
              </FormHelperText>
            )}
          </Box>
          <Box
            sx={{
              "& label": {
                mb: 1,
                fontSize: "12px",
                display: "block",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <label>
                  {" "}
                  {t("Ticket_add.add_ticket.labels.billboard_name")}
                </label>
                <FormControl fullWidth className="selectdiv" size="small">
                  <Select
                    size="large"
                    sx={{
                      "&.MuiInputBase-root": {
                        border: "0px",
                        fontSize: "15px",
                        width: "100%",

                        borderRadius: "10px",
                        background: "#00000066",
                        color: "#fff",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                      },
                      "& fieldset": {
                        display: "none",
                      },
                      "& .MuiSelect-select": {
                        paddingRight:
                          localStorage.getItem("i18nextLng") === "ar" &&
                          "14px !important",

                        paddingLeft:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "32px !important"
                            : "14px",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#fff",
                        marginRight:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "0"
                            : "10px",
                        marginLeft:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "10px"
                            : "0px",
                        right:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "auto"
                            : "0",
                        left:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "0"
                            : "auto",
                        position:
                          localStorage.getItem("i18nextLng") === "ar" &&
                          "absolute",
                      },
                    }}
                    defaultValue="Select Billboard"
                    name="billboard"
                    value={billboard}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    renderValue={(v) => v}
                    displayEmpty
                    IconComponent={() => <KeyboardArrowDownIcon />}
                    MenuProps={MenuProps}
                  >
                    {billboards
                      .sort((a, b) => {
                        const extractNumber = (name) =>
                          parseInt(name.replace(/\D/g, ""));

                        const isMGroup = (name) => /M/.test(name);

                        const aIsMGroup = isMGroup(a);
                        const bIsMGroup = isMGroup(b);

                        if (aIsMGroup && !bIsMGroup) {
                          return 1; // b comes before a
                        }
                        if (!aIsMGroup && bIsMGroup) {
                          return -1; // a comes before b
                        }

                        const aNumber = extractNumber(a);
                        const bNumber = extractNumber(b);

                        return aNumber - bNumber;
                      })
                      .map((item) => {
                        const billboardData = loadBillboards.find(
                          (loadBillboard) =>
                            item === loadBillboard.billboardName
                        );

                        if (billboardData) {
                          return (
                            <MenuItem
                              key={item}
                              value={item}
                              // className="droppop-slect"
                            >
                              <ListItemText
                                primary={`${billboardData?.address || ""}`}
                              />
                            </MenuItem>
                          );
                        }
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <label>{t("Ticket_add.add_ticket.labels.closing_date")}</label>
                <SingleDatePiker
                  value={
                    formik.values.closing_at
                      ? moment(formik.values.closing_at).format("YYYY-MM-DD")
                      : ""
                  }
                  name="closing_at"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onData={handleStartDateChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <label>{t("Ticket_add.add_ticket.labels.ticket_type")}</label>
                <Select
                  size="large"
                  sx={{
                    "&.MuiInputBase-root": {
                      border: "0px",
                      fontSize: "15px",
                      width: "100%",

                      borderRadius: "10px",
                      background: "#00000066",
                      color: "#fff",
                      textAlign:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "right"
                          : "left",
                    },
                    "& fieldset": {
                      display: "none",
                    },
                    "& .MuiSelect-select": {
                      paddingRight:
                        localStorage.getItem("i18nextLng") === "ar" &&
                        "14px !important",
                      paddingLeft:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "32px !important"
                          : "14px",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#fff",
                      marginRight:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "0"
                          : "10px",
                      marginLeft:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "10px"
                          : "0px",
                      right:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "auto"
                          : "0",
                      left:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "0"
                          : "auto",
                      position:
                        localStorage.getItem("i18nextLng") === "ar" &&
                        "absolute",
                    },
                  }}
                  defaultValue="Select Billboard"
                  value={formik.values.ticket_type}
                  name="ticket_type"
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChangeType(e);
                  }}
                  renderValue={(v) =>
                    TicketTypes?.find((i) => i.value === v)?.name
                  }
                  onBlur={formik.handleBlur}
                  displayEmpty
                  IconComponent={() => <KeyboardArrowDownIcon />}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value={""}
                    disabled
                    sx={{
                      justifyContent:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "flex-end"
                          : "flex-start",
                    }}
                  >
                    {t("Ticket_add.add_ticket.labels.ticket_type")}
                  </MenuItem>
                  {TicketTypes.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}
                      // className="droppop-slect"
                      sx={{
                        justifyContent:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "flex-end"
                            : "flex-start",
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.ticket_type && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errors.ticket_type}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <label>
                  {t("Ticket_add.add_ticket.labels.ticket_subtype")}
                </label>
                <Select
                  size="large"
                  sx={{
                    "&.MuiInputBase-root": {
                      border: "0px",
                      fontSize: "15px",
                      width: "100%",

                      borderRadius: "10px",
                      background: "#00000066",
                      color: "#fff",
                      textAlign:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "right"
                          : "left",
                    },
                    "& fieldset": {
                      display: "none",
                    },
                    "& .MuiSelect-select": {
                      paddingRight:
                        localStorage.getItem("i18nextLng") === "ar" &&
                        "14px !important",
                      paddingLeft:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "32px !important"
                          : "14px",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#fff",
                      marginRight:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "0"
                          : "10px",
                      marginLeft:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "10px"
                          : "0px",
                      right:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "auto"
                          : "0",
                      left:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "0"
                          : "auto",
                      position:
                        localStorage.getItem("i18nextLng") === "ar" &&
                        "absolute",
                    },
                  }}
                  defaultValue="Select Ticket Subtype"
                  value={formik.values.ticket_sub_type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  displayEmpty
                  IconComponent={() => <KeyboardArrowDownIcon />}
                  MenuProps={MenuProps}
                  name="ticket_sub_type"
                >
                  {ticketSubType.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}
                      className="droppop-slect"
                      sx={{
                        justifyContent:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "flex-end"
                            : "flex-start",
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.ticket_sub_type && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errors.ticket_sub_type}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} lg={12}>
                <label>
                  {t("Ticket_add.add_ticket.labels.ticket_assignee")}
                </label>
                <Select
                  size="large"
                  sx={{
                    "&.MuiInputBase-root": {
                      border: "0px",
                      fontSize: "15px",
                      width: "100%",

                      borderRadius: "10px",
                      background: "#00000066",
                      color: "#fff",
                      textAlign:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "right"
                          : "left",
                    },
                    "& fieldset": {
                      display: "none",
                    },
                    "& .MuiSelect-select": {
                      paddingRight:
                        localStorage.getItem("i18nextLng") === "ar" &&
                        "14px !important",
                      paddingLeft:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "32px !important"
                          : "14px",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#fff",
                      marginRight:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "0"
                          : "10px",
                      marginLeft:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "10px"
                          : "0px",
                      right:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "auto"
                          : "0",
                      left:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "0"
                          : "auto",
                      position:
                        localStorage.getItem("i18nextLng") === "ar" &&
                        "absolute",
                    },
                  }}
                  multiple
                  defaultValue={formik.values.ticket_assignee.map(
                    (i) => i?._id
                  )}
                  value={formik.values.ticket_assignee}
                  name="ticket_assignee"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  renderValue={(selected) => (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {selected.map((i) => {
                        const emp = listOfEmployees.find((l) => l._id === i);
                        const value = `${emp?.fullname}, (${emp?.email})`;
                        return (
                          <Box
                            component={"span"}
                            key={value}
                            style={{
                              margin: 2,
                              color: "#fff",
                            }}
                          >
                            {value}
                          </Box>
                        );
                      })}
                    </div>
                  )}
                  IconComponent={() => <KeyboardArrowDownIcon />}
                  MenuProps={MenuAssignee}
                >
                  {listOfEmployees
                    .filter(
                      (item) =>
                        !formik.values.ticket_follower.includes(item._id)
                    )
                    .map((item, index) => {
                      // console.log("+++++", item);
                      return (
                        <MenuItem
                          key={index}
                          value={item._id}
                          sx={{
                            backgroundColor: formik.values.ticket_assignee.find(
                              (i) => i?._id === item._id
                            )
                              ? "#bfdfff"
                              : "",
                            justifyContent:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "flex-end"
                                : "flex-start",
                            direction:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "rtl"
                                : "ltr",
                          }}
                          className="droppop-slect"
                        >
                          <Checkbox
                            sx={{
                              marginRight:
                                localStorage.getItem("i18nextLng") === "ar"
                                  ? "0px !important"
                                  : "12px",
                              marginLeft:
                                localStorage.getItem("i18nextLng") === "ar"
                                  ? "12px !important"
                                  : "0",
                            }}
                            checked={
                              formik.values.ticket_assignee.indexOf(item._id) >
                              -1
                            }
                          />
                          <ListItemText primary={`${item.fullname}`} />
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              <Grid item xs={12} lg={12}>
                <label>
                  {t("Ticket_add.add_ticket.labels.ticket_followers")}
                </label>
                <Select
                  size="large"
                  sx={{
                    "&.MuiInputBase-root": {
                      border: "0px",
                      fontSize: "15px",
                      width: "100%",

                      borderRadius: "10px",
                      background: "#00000066",
                      color: "#fff",
                      textAlign:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "right"
                          : "left",
                    },
                    "& fieldset": {
                      display: "none",
                    },
                    "& .MuiSelect-select": {
                      paddingRight:
                        localStorage.getItem("i18nextLng") === "ar" &&
                        "14px !important",
                      paddingLeft:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "32px !important"
                          : "14px",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#fff",
                      marginRight:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "0"
                          : "10px",
                      marginLeft:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "10px"
                          : "0px",
                      right:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "auto"
                          : "0",
                      left:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "0"
                          : "auto",
                      position:
                        localStorage.getItem("i18nextLng") === "ar" &&
                        "absolute",
                    },
                  }}
                  multiple
                  defaultValue={formik.values.ticket_follower.map(
                    (i) => i?._id
                  )}
                  value={formik.values.ticket_follower}
                  name="ticket_follower"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  renderValue={(selected) => (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {selected.map((i) => {
                        const emp = listOfEmployees.find((l) => l._id === i);
                        const value = `${emp?.fullname}, (${emp?.email})`;
                        return (
                          <Box
                            component={"span"}
                            key={value}
                            style={{
                              margin: 2,
                              color: "#fff",
                            }}
                          >
                            {value}
                          </Box>
                        );
                      })}
                    </div>
                  )}
                  IconComponent={() => <KeyboardArrowDownIcon />}
                  MenuProps={MenuAssignee}
                >
                  {listOfEmployees
                    .filter(
                      (item) =>
                        !formik.values.ticket_assignee.includes(item._id)
                    )
                    .map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item._id}
                        style={{
                          backgroundColor: formik.values.ticket_follower.find(
                            (i) => i?._id === item._id
                          )
                            ? "#bfdfff"
                            : "",
                          textAlign:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "right"
                              : "left",
                          direction:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "rtl"
                              : "ltr",
                        }}
                        className="droppop-slect"
                      >
                        <Checkbox
                          sx={{
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "0px !important"
                                : "12px",
                            marginLeft:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "12px !important"
                                : "0",
                          }}
                          checked={
                            formik.values.ticket_follower.indexOf(item._id) > -1
                          }
                        />
                        <ListItemText primary={`${item.fullname}`} />
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={
            localStorage.getItem("i18nextLng") === "ar"
              ? "flex-start"
              : "flex-end"
          }
          sx={{ mt: 5 }}
        >
          <Button
            sx={{
              background: "#FFFFFF",
              color: "#000",
              borderRadius: "8px",
              textTransform: "capitalize",
              width: "150px",
              "&:hover": { background: "#FFFFFF", color: "#000" },
            }}
            type="submit"
            disabled={formik.isSubmitting}
            onClick={() => console.log("<ADD> Submit button", formik.errors)}
          >
            {userData.data.doc.role === "Admin" ||
            userData.data.doc.role === "SuperAdmin"
              ? t("Ticket_add.add_ticket.buttons.approve")
              : t("Ticket_add.add_ticket.buttons.save")}
          </Button>
          <Button
            sx={{
              background: "transparent",
              color: "#fff",
              borderRadius: "8px",
              textTransform: "capitalize",
              border: "1px solid #fff",
              width: "150px",
              "&:hover": { background: "#FFFFFF", color: "#000" },
            }}
            onClick={() => {
              console.log("<Add > cancel button ");
              setOpenModal(false);
            }}
          >
            {t("Ticket_add.add_ticket.buttons.cancel")}
          </Button>
        </Stack>
      </form>
    </>
  );
}
