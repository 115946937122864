import React from 'react'
import '../CustomLoader/CustomLoader.css';
import { IMAGE_TYPES } from '../utility-files/default-values/DefaultValues';
import {SVG} from "../icon/svg.file"

function CustomLoader() {
    return (
        <div className='loader-wrapper'>
        <img src={IMAGE_TYPES.LOAD_GIF} alt="loader"/>
        </div>
    )
}

export default CustomLoader