import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useState } from "react";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#493932",
    color: "#CBD5E0",
    padding: "16px",
    fontSize: 12,
    border: 0,
    whiteSpace: "nowrap",
    fontFamily:
      localStorage.getItem("i18nextLng") === "ar"
        ? `"Cairo", sans-serif`
        : `"Manrope", sans-serif`,
    "@media(max-width:992px)": { padding: "10px" },
    cursor: "pointer", // Make cursor a pointer to indicate it's clickable
    "&:first-child": {
      //borderRadius: "10px 0px 0px 10px",
      borderRadius:
        localStorage.getItem("i18nextLng") === "ar" ? "0px 10px 10px 0px" : 0,
    },
    "&:hover": {
      backgroundColor: "#3a3a3a", // Change this to your desired hover color for header cells
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "16px",
    borderBottom: "1px solid #493932",
    whiteSpace: "nowrap",
    fontFamily:
      localStorage.getItem("i18nextLng") === "ar"
        ? `"Cairo", sans-serif`
        : `"Manrope", sans-serif`,
    direction: localStorage.getItem("i18nextLng") === "ar" ? "ltr" : "",
    "@media(max-width:992px)": { padding: "10px" },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "#3a3a3a", // Change this to your desired hover color for rows
  },
}));

export default function CommanTable({ columns, rows }) {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (columnKey) => {
    if (columnKey === "action") return; // Prevent sorting by the action column

    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const sortedRows = React.useMemo(() => {
    if (!rows) return [];
    const sortableRows = [...rows];
    if (sortConfig.key) {
      sortableRows.sort((a, b) => {
        const aValue = a[sortConfig.key]?.props?.children || a[sortConfig.key];
        const bValue = b[sortConfig.key]?.props?.children || b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableRows;
  }, [rows, sortConfig]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        overflow: "hidden",
        overflowX: "auto",
        background: "transparent",
        "@media(max-width:992px)": {
          border: "1px solid #493932",
        },
      }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead className="bg-thead">
          <TableRow className="MuiTableRow-th">
            {columns.map((data) => (
              <StyledTableCell
                key={data.key}
                className={data.className}
                sx={{ color: "#fff" }}
                onClick={() => handleSort(data.key)}
              >
                {data.name}
                <span style={{ marginLeft: "8px", fontSize: "12px" }}>
                  <span
                    style={{
                      color:
                        sortConfig.key === data.key &&
                        sortConfig.direction === "asc"
                          ? "#CBD5E0"
                          : "#666",
                    }}
                  >
                    ↑
                  </span>
                  <span
                    style={{
                      color:
                        sortConfig.key === data.key &&
                        sortConfig.direction === "desc"
                          ? "#CBD5E0"
                          : "#666",
                    }}
                  >
                    ↓
                  </span>
                </span>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row, index) => (
            <StyledTableRow key={index}>
              {columns.map((column) => (
                <StyledTableCell sx={{ color: "#fff" }} key={column.key}>
                  {row[column.key]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
