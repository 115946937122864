import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AllTickets from "./all-ticket";
import Add from "./Add";
import * as global from "../../constant/global";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import { useDispatch, useSelector } from "react-redux";
import action from "../../new redux/Action";
import constants from "../../new redux/Types/actionTypes";
import { useSearchParams } from "react-router-dom";
import NoTickets from "./notFound";
import { TramOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import BugReportIcon from "@mui/icons-material/BugReport";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";

import AddIcon from "@mui/icons-material/Add";
import { SVG } from "../../icon/svg.file";
import { isPlatform } from "@ionic/react";

export default function Tickets() {
  const matches = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  const [query, setQuery] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const [pending, setPending] = useState(false);
  const ticketCount = useSelector((state) => state.allTicketCount);
  // const [ticketCount, setTicketCount] = useState({});
  const dispatch = useDispatch();
  const UserData = useSelector((state) => state.UserData);
  const toggle = useSelector((state) => state.mobileViewTicketToggle);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const tab = parseInt(query.get("id"));
  const [tabs, setTabs] = useState(tab || 2);
  const handleTabs = (id) => {
    query.set("id", id);
    setQuery(query);
    setTabs(id);
  };

  // const getTotalTicketCount = async () => {
  //   let request;
  //   setPending(true);
  //   request = getRequestForApi(global.GET_ALL_TICKET_COUNT, methodType.GET);
  //   await callHttpRequest(request)
  //     .then(({ data }) => {
  //       // dispatch(action(constants.allTicketCount, data));
  //       // setTicketCount(data);
  //     })
  //     .catch(({ res }) => {})
  //     .finally(() => setPending(false));
  // };
  useEffect(() => {
    // getTotalTicketCount();

    if (UserData?.data) getBoradCamera();
  }, [UserData]);

  const getBoradCamera = async () => {
    setPending(TramOutlined);
    let request = getRequestForApi(
      global.GET_BILLBOARD_CAMERA + `${UserData?.data?.doc?.address?.country}`,
      methodType.GET
    );
    await callHttpRequest(request)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          if (response?.data?.data?.docs) {
            let dataList = response?.data?.data?.docs;
            dispatch(action(constants.cameraBillBoard, dataList));
          }
        }
      })
      .catch((err) => {})
      .finally(() => setPending(false));
  };

  const userTabs = [
    {
      id: 1,
      label: `${t("Ticket_index.tickets.tabs.inProgress")}(${
        ticketCount.approveTicket
      })`,
      icon: (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <AutoModeIcon />
          <span>{ticketCount.approveTicket}</span>
        </Stack>
      ),
    },
    {
      id: 2,
      label: `${t("Ticket_index.tickets.tabs.unapprove")}(${
        ticketCount.unapproveTicket
      })`,
      icon: (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <BugReportIcon />
          <span>{ticketCount.unapproveTicket}</span>
        </Stack>
      ),
    },
    {
      id: 3,
      label: `${t("Ticket_index.tickets.tabs.completed")}(${
        ticketCount.closeTicket
      })`,
      icon: (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <PublishedWithChangesIcon />
          <span>{ticketCount.closeTicket}</span>
        </Stack>
      ),
    },
    {
      id: 4,
      label: `${t("Ticket_index.tickets.tabs.disapprove")}(${
        ticketCount.disapproveTicket
      })`,
      icon: (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <CancelPresentationIcon />{" "}
          <span> {ticketCount.disapproveTicket}</span>
        </Stack>
      ),
    },
    {
      id: 5,
      label: `${t("Ticket_index.tickets.tabs.archieve")}(${
        ticketCount.archieveTicket
      })`,
      icon: (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <MoveToInboxIcon /> <span>{ticketCount.archieveTicket}</span>
        </Stack>
      ),
    },
  ];
  return (
    <>
      {matches && toggle == true ? (
        ""
      ) : (
        <>
          <Typography
            variant="h1"
            sx={{
              fontSize: "30px",
              color: "#fff",
              fontWeight: "600",
              mb: 3,
              textAlign:
                localStorage.getItem("i18nextLng") === "ar" ? "right" : "left",
              "@media(max-width:992px)": {
                fontSize: "24px",
              },
            }}
          >
            {t("Ticket_index.tickets.title")}
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
          >
            <Box
              sx={{
                border: "1px solid #322E27",
                "@media(max-width:992px)": {
                  width: "100%",
                },
                "& ul": {
                  display: "flex",
                  margin: "0px",
                  padding: "0px",
                  listStyle: "none",

                  "& li": {
                    color: "#fff",
                    fontSize: "16px",
                    padding: "16px 25px",
                    cursor: "pointer",
                    fontWeight: "600",
                    "@media(max-width:992px)": {
                      padding: "6px",
                    },

                    borderBottom: "2px solid transparent",
                    "&:hover": {
                      background:
                        "linear-gradient(to right, #F7C409 0%, #B44C06 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      borderBottom: "2px solid #B44C06",
                      "& svg": {
                        color: "#B44C06",
                      },
                    },
                  },
                },
              }}
            >
              <ul>
                {userTabs.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleTabs(item.id)}
                    style={{
                      background:
                        tabs === item.id
                          ? "linear-gradient(to right, #F7C409 0%, #B44C06 100%)"
                          : "",
                      WebkitBackgroundClip: tabs === item.id ? "text" : "",
                      WebkitTextFillColor:
                        tabs === item.id ? "transparent" : "",
                      borderBottom: tabs === item.id ? "2px solid #B44C06" : "",
                    }}
                  >
                    {matches ? item.icon : item.label}
                  </li>
                ))}
              </ul>
            </Box>
            <Box
              sx={{
                ml: localStorage.getItem("i18nextLng") === "ar" ? 0 : 2,
                mr:
                  localStorage.getItem("i18nextLng") === "ar"
                    ? "16px !important"
                    : 0,
              }}
            >
              <Button
                sx={{
                  background: "#FFFFFF",
                  color: "#000",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  width: "150px",
                  "&:hover": { background: "#FFFFFF", color: "#000" },
                  "@media(max-width:768px)": {
                    width: "auto",
                    padding: "5px",
                    minWidth: "auto",
                  },
                }}
                onClick={toggleDrawer(true)}
              >
                {matches ? <AddIcon /> : t("Ticket_index.tickets.addButton")}
              </Button>
            </Box>
          </Stack>
        </>
      )}
      {tabs === 1 && ticketCount.approveTicket > 0 ? (
        <AllTickets
          filter={"approve"}
          ticketCount={ticketCount.approveTicket}
        />
      ) : tabs === 2 && ticketCount.unapproveTicket > 0 ? (
        <AllTickets
          filter={"unapprove"}
          ticketCount={ticketCount.unapproveTicket}
        />
      ) : tabs === 3 && ticketCount.closeTicket > 0 ? (
        <AllTickets filter={"close"} ticketCount={ticketCount.closeTicket} />
      ) : tabs === 4 && ticketCount.disapproveTicket > 0 ? (
        <AllTickets
          filter={"disapprove"}
          ticketCount={ticketCount.disapproveTicket}
        />
      ) : tabs === 5 && ticketCount.archieveTicket > 0 ? (
        <AllTickets
          filter={"archieve"}
          ticketCount={ticketCount.archieveTicket}
        />
      ) : (
        <NoTickets />
      )}
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        anchor={matches ? "bottom" : "right"}
        BackdropProps={{
          sx: {
            bottom: matches ? "80px" : "0px", // Custom positioning backgroundColor: "rgba(0, 0, 0, 0.5)", // Custom backdrop color (optional)
          },
        }}
        sx={{
          "& .MuiPaper-root": {
            background: "#352614",
            maxWidth: "760px",
            top: "0px",
            p: 3,
            minWidth: "760px",
            bottom: matches ? "80px" : "0px",
            color: "#fff",
            "@media(max-width:992px)": {
              minWidth: "100%",
              maxWidth: "100%",
              // height: matches ? "calc(100% - 157px)" : "100%",
            },
          },
        }}
      >
        <Box
          onClick={toggleDrawer(false)}
          sx={{
            position: "absolute",
            right: "14px",
            top: "14px",
            cursor: "pointer",
            "& svg": {
              width: matches && "30px",
            },
          }}
        >
          <SVG.CloseIcon />
        </Box>
        <Add setOpenModal={setOpen} />
      </Drawer>
    </>
  );
}
