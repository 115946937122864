import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { SVG } from "../../../icon/svg.file";
import {
  callHttpRequest,
  methodType,
} from "../../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../../utility-files/api-caller/CommonRequest";
import * as global from "../../../constant/global";
import { getDateFormat } from "../../../utility-files/date-util/DateHandling";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import ReactPlayer from "react-player";
import { isIntegerValue } from "../../../utility-files/data-util/DataHandling";
import * as StorageHandling from "../../../utility-files/storage-util/StorageHandling";
import { useTranslation } from "react-i18next";

export default function EditDetails() {
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const [adDetail, setAdDetails] = useState();
  const [cameraBillBoard, setCameraBillBoard] = useState();
  const params = useParams();
  const playerRef = useRef(null);
  const url = new URL(window.location.href);
  const id = url.searchParams.get("id");
  const videoUrl = `${global.BASE_URL}/media/videos/${adDetail?.data?.video}`;
  useEffect(() => {
    getAdDetails();
    getBoardCamera();
  }, []);

  const countryName =
    localStorage.getItem(StorageHandling.storageKey.USERDATA) ||
    sessionStorage.getItem(StorageHandling.storageKey.USERDATA);

  const getAdDetails = async () => {
    setPending(true);
    let requset, variables;
    requset = getRequestForApi(
      global.DETAILS_ADVERTISING + `${params.id}`,
      variables,
      methodType.GET
    );
    await callHttpRequest(requset)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          const detailData = response?.data;
          setAdDetails(detailData);
          setPending(false);
        }
      })
      .catch((err) => {
        setPending(false);
      });
  };

  const getBoardCamera = async () => {
    let request;
    request = getRequestForApi(
      global.GET_BILLBOARD_CAMERA + `${countryName}`,
      methodType.GET
    );
    await callHttpRequest(request)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          if (response?.data?.data?.docs) {
            let dataList = response?.data?.data?.docs;
            setCameraBillBoard(dataList);
          }
        }
      })
      .catch((err) => {});
  };

  const billBoarddetails = Array.isArray(cameraBillBoard)
    ? cameraBillBoard.filter((element) =>
        adDetail?.data?.billboard.includes(element.billboardName)
      )
    : [];
  const ans = billBoarddetails.map((item) => item.billboardName);
  const formattedANs = ans.join(", ");
  const addressDat = billBoarddetails.map((item) => item.address);
  const formattedAddresses = addressDat.join(", ");
  const matches = useMediaQuery("(max-width:768px)");

  return (
    <>
      {pending ? (
        <CustomLoader />
      ) : (
        <>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            spacing={2}
            alignItems={{ xs: "self-start", lg: "center" }}
            justifyContent="space-between"
            sx={{ mb: 3 }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: "30px",
                color: "#fff",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                "@media(max-width:992px)": {
                  fontSize: "24px",
                },
              }}
            >
              <IconButton LinkComponent={Link} to={`/advertisement?id=${id}`}>
                <SVG.ArrowRight />
              </IconButton>{" "}
              <span> {t("Ad_Details.advertisementDetail")}</span>
            </Typography>
            <Stack
              direction={"row"}
              spacing={{ xs: 0, lg: 2 }}
              alignItems={"center"}
              sx={{
                "@media(max-width:992px)": { width: "100%" },
                "& button": {
                  width: "141px",
                  "@media(max-width:992px)": {
                    width: "100%",
                  },
                },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background:
                    "linear-gradient(180deg, #F7C409 0%, #B44C06 100%)",
                  color: "#fff !important",
                  borderRadius: "10px !important",
                }}
              >
                {adDetail?.data?.type}
              </Button>
              <Button
                variant="contained"
                sx={{
                  background:
                    "linear-gradient(180deg, #F7C409 0%, #B44C06 100%)",
                  color: "#fff !important",
                  borderRadius: "10px !important",
                  ml:
                    localStorage.getItem("i18nextLng") === "ar"
                      ? "0px !important"
                      : "16px !important",
                  mr:
                    localStorage.getItem("i18nextLng") === "ar"
                      ? "16px !important"
                      : "0px !important",
                }}
              >
                {adDetail?.data?.status}
              </Button>
            </Stack>
          </Stack>
          <Box
            sx={{
              mb: 3,
              border: "1px solid #555555",
              py: 1,
              px: 2,
              borderRadius: "5px",
              fontWeight: "700",
              fontSize: "18px",
              color: "#fff",
              textAlign:
                localStorage.getItem("i18nextLng") === "ar" ? "right" : "left",
            }}
          >
            {adDetail?.data?.title}
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Card sx={{ borderRadius: "16px", mb: 2 }} elevation={0}>
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      p: 2,
                      pt: 3,

                      "&:last-child": { pb: 2 },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "16px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: 3,
                      }}
                    >
                      {t("Ad_Details.advertiserDetails")}
                    </Typography>
                    <Stack
                      direction={"column"}
                      spacing={1}
                      component={"ul"}
                      sx={{
                        m: 0,
                        p: 0,
                        listStyle: "none",
                        "& li": {
                          background: "#53514E",
                          padding: "12px 25px",
                          borderRadius: "5px",
                          fontSize: "14px",
                        },
                      }}
                    >
                      <Stack
                        component={"li"}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <SVG.UserIcon />{" "}
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {adDetail?.data?.advertiser?.firstname &&
                          adDetail?.data?.advertiser?.lastname
                            ? `${adDetail.data.advertiser.firstname} ${adDetail.data.advertiser.lastname}`
                            : t("Ad_Details.noDataAvailable")}
                        </Box>{" "}
                      </Stack>
                      <Stack
                        component={"li"}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <SVG.PhoneIcon />{" "}
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {adDetail?.data?.advertiser?.phone ||
                            t("Ad_Details.noDataAvailable")}
                        </Box>
                      </Stack>
                      <Stack
                        component={"li"}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <SVG.Email />{" "}
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {adDetail?.data?.advertiser?.email ||
                            t("Ad_Details.noDataAvailable")}
                        </Box>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
                <Card sx={{ borderRadius: "16px", mb: 2 }} elevation={0}>
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      p: 2,
                      pt: 3,

                      "&:last-child": { pb: 2 },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "16px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: 3,
                      }}
                    >
                      {t("Ad_Details.campaignDetails")}
                    </Typography>
                    <Stack
                      direction={"column"}
                      spacing={1}
                      component={"ul"}
                      sx={{
                        m: 0,
                        p: 0,
                        listStyle: "none",
                        "& li": {
                          background: "#53514E",
                          padding: "12px 25px",
                          borderRadius: "5px",
                          fontSize: "14px",
                        },
                      }}
                    >
                      <Stack
                        component={"li"}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <SVG.CalenderIcon />{" "}
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {t("Ad_Details.campaignDetails")}
                        </Box>
                      </Stack>
                      <Stack
                        component={"li"}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <SVG.CalenderIcon />{" "}
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {t("Ad_Details.from")}{" "}
                          <b>{getDateFormat(adDetail?.data?.start_at)}</b>
                        </Box>
                      </Stack>
                      <Stack
                        component={"li"}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <SVG.CalenderIcon />{" "}
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {" "}
                          {t("Ad_Details.to")}{" "}
                          <b>{getDateFormat(adDetail?.data?.closing_at)}</b>
                        </Box>
                      </Stack>
                      <Stack
                        component={"li"}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <SVG.BillBoardIcon />{" "}
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {t("Ad_Details.billboard")}:{" "}
                          <b>{formattedAddresses}</b>
                        </Box>
                      </Stack>
                      <Stack
                        component={"li"}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <SVG.Pencil />{" "}
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {t("Ad_Details.createdAt")}{" "}
                          <b>{getDateFormat(adDetail?.data?.createdAt)}</b>
                        </Box>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
                <Card sx={{ borderRadius: "16px", mb: 2 }} elevation={0}>
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      p: 2,
                      pt: 3,

                      "&:last-child": { pb: 2 },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "16px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: 3,
                      }}
                    >
                      {t("Ad_Details.campaignCost")}
                    </Typography>
                    <Stack
                      direction={"column"}
                      spacing={1}
                      component={"ul"}
                      sx={{
                        m: 0,
                        p: 0,
                        listStyle: "none",
                        "& li": {
                          background: "#53514E",
                          padding: "12px 25px",
                          borderRadius: "5px",
                          fontSize: "14px",
                          "& svg": {
                            width: "20px",
                            height: "20px",
                          },
                        },
                      }}
                    >
                      <Stack
                        component={"li"}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <SVG.AutomaticCalculation />{" "}
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {t("Ad_Details.automaticCalculation")}:{" "}
                          {isIntegerValue(adDetail?.data?.campaign_cost)}
                        </Box>
                      </Stack>
                      <Stack
                        component={"li"}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <SVG.AgentProposal />{" "}
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {t("Ad_Details.agentProposal")}:
                          {adDetail?.data?.sales_proposal_cost}
                        </Box>
                      </Stack>
                      <Stack
                        component={"li"}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <SVG.FinalCost />{" "}
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {t("Ad_Details.finalCost")}:
                          {adDetail?.data?.final_cost}
                        </Box>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Card sx={{ borderRadius: "16px", mb: 2 }} elevation={0}>
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      p: 2,
                      pt: 3,

                      "&:last-child": { pb: 2 },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "16px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: 3,
                      }}
                    >
                      {t("Ad_Details.videoAdPreview")}
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: "12px",
                        overflow: "hidden",
                        width: "50%",
                        "& .react-player": {
                          "@media(max-width:992px)": {
                            width: "100% !important",
                            height: "100% !important",
                          },
                        },
                      }}
                    >
                      <ReactPlayer
                        className="react-player"
                        url={videoUrl}
                        width="100%"
                        height="100%"
                        controls={true} // Remove controls
                        playing={matches ? false : true}
                        loop={matches ? false : true}
                        muted={matches ? false : true}
                        style={{ borderRadius: "12px" }}
                        playbackRate={1} // Default playback rate
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                            tracks: [
                              {
                                kind: "subtitles",
                                src: "subtitles.vtt",
                                srcLang: "en",
                                label: "English",
                              },
                            ],
                          },
                        }}
                        onEnded={() => console.log("Video ended")}
                        onError={(error) =>
                          console.error("Video error:", error)
                        }
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Card sx={{ borderRadius: "16px", mb: 2 }} elevation={0}>
              <CardContent
                sx={{
                  background: "#322E27",
                  color: "#fff",
                  p: 2,
                  pt: 3,

                  "&:last-child": { pb: 2 },
                }}
              >
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6} lg={9}>
                      <Typography
                        variant="h1"
                        sx={{
                          fontSize: "16px",
                          color: "#fff",
                          fontWeight: "600",
                          mb: 3,
                        }}
                      >
                        {t("Ad_Details.date")}
                      </Typography>
                      <Stack direction="column" spacing={2}>
                        {adDetail?.data?.adExposures ? (
                          adDetail.data.adExposures
                            .slice(1)
                            .map((exposure, index) => (
                              <Stack
                                key={index}
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <SVG.CalenderIcon />{" "}
                                <Box
                                  sx={{
                                    fontWeight: "700",
                                    fontSize: "14px",
                                  }}
                                >
                                  {getDateFormat(exposure.date)}
                                </Box>
                              </Stack>
                            ))
                        ) : (
                          <Typography variant="body1" color="textSecondary">
                            {t("Ad_Details.dataNotAvailable")}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                      <Typography
                        variant="h1"
                        sx={{
                          fontSize: "15px",
                          color: "#fff",
                          fontWeight: "500",
                          mb: 3,
                        }}
                      >
                        {t("Ad_Details.adExposure")}
                      </Typography>

                      <Stack direction="column" spacing={1.6}>
                        {adDetail?.data?.adExposures ? (
                          adDetail.data.adExposures
                            .slice(1)
                            .map((exposure, index) => {
                              return (
                                <Stack
                                  key={index}
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Box
                                    sx={{
                                      fontWeight: "700",
                                      fontSize: "14px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography>
                                      {t("Ad_Details.adExposure")}:{" "}
                                      {exposure.adExposure}
                                    </Typography>
                                  </Box>
                                </Stack>
                              );
                            })
                        ) : (
                          <Typography variant="body1" color="textSecondary">
                            {t("Ad_Details.dataNotAvailable")}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Box
                        sx={{
                          my: 2,
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                      >
                        {t("Ad_Details.totalExposure")}:{" "}
                        {adDetail?.data?.total_adExposure}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
}
