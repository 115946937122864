/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import logo from "../images/favicon.ico";

import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import {
  Avatar,
  Badge,
  // Button,
  IconButton,
  // InputAdornment,
  Menu,
  MenuItem,
  // OutlinedInput,
  Popover,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Background from "../images/sidebar-bg.png";
import Topbar from "../images/topbar.png";
import "./sidebar.css";
import { SVG } from "../icon/svg.file";
import EmailMessage from "./message.component";
import * as StorageHandling from "../utility-files/storage-util/StorageHandling";
import * as global from "../constant/global";
import Language from "./select-language";
import {
  callHttpRequest,
  methodType,
} from "../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../utility-files/api-caller/CommonRequest";
import { useDispatch, useSelector } from "react-redux";
import action from "../new redux/Action";
import constants from "../new redux/Types/actionTypes";
import {
  SocketContext,
  useSocket,
} from "../utility-files/socket/socket.provider";
import { requestForToken } from "../utility-files/firebase";
import { useTranslation } from "react-i18next";

import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Notification from "./notification.component";
import { isPlatform } from "@ionic/react";
const drawerWidth = 280;

export default function Sidebar(props) {
  const matches = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  const { i18n } = useTranslation("en");
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useSearchParams();
  const refresh = query.get("ticketTabs") && query.get("ticket") ? true : false;
  const ticket = useSelector((state) => state.ticket);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [emailMessage, setEmailMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const open2 = Boolean(emailMessage);
  const open3 = Boolean(message);
  const UserData = useSelector((state) => state.UserData);
  const Notifications = useSelector((state) => state.allNotifications);
  const dispatch = useDispatch();
  const id = open2 ? "emailMessage" : undefined;
  const socket = useSocket();
  const { isUnreadMsgs, setIsUnreadMsgs } = React.useContext(SocketContext);
  const [newMsg, setNewMsg] = React.useState(null);
  const chats = useSelector((state) => state.chats);
  const [tickets, setTickets] = React.useState({
    data: null,
    isLoading: false,
  });
  const [totalMessageCount, setTotalMessageCount] = React.useState(0);
  const [pending, setPending] = React.useState(false);
  const ticketCount = useSelector((state) => state.allTicketCount);
  const [loader, setLoader] = React.useState(false);
  const [notificationLoader, setNotificationLoader] = React.useState(false);
  const [unreadMessageCount, setunreadMessageCount] = React.useState(true);

  const handleEmailMessage = (event) => {
    setEmailMessage(event.currentTarget);
  };
  const handleMessage = (event) => {
    setMessage(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMessage(null);
    setEmailMessage(null);
  };

  const handleClearAllNotification = async () => {
    if (Notifications && Notifications?.data?.notifications?.length > 0) {
      const notificationList = Notifications?.data?.notifications;
      setNotificationLoader(true);
      for (const noti of notificationList) {
        let request = getRequestForApi(
          `${global.GET_NOTIFICATION}/${noti?._id}/read`,
          {},
          methodType.PATCH
        );
        await callHttpRequest(request)
          .then(({ data }) => {})
          .catch((err) => {
            console.error("<Sidebar> handleReadNotification", err);
            // setNotificationLoader(false);
          });
      }
      getNotification();
      setNotificationLoader(false);
    }
  };

  const handleReadNotification = async (id) => {
    let request;
    // setNotificationLoader(true);
    request = getRequestForApi(
      `${global.GET_NOTIFICATION}/${id}/read`,
      {},
      methodType.PATCH
    );
    await callHttpRequest(request)
      .then(({ data }) => {
        getNotification();
      })
      .catch((err) => {
        console.error("<Sidebar> handleReadNotification", err);
        // setNotificationLoader(false);
      })
      .finally(() => {
        // setNotificationLoader(false);
      });
  };

  const handleCloseNotification = (index) => {
    // console.log("<Sidebar> handleCloseNotification", index);

    const updatedNotifications = Notifications?.data?.notifications?.splice(
      index,
      1
    );
    dispatch(
      action(constants?.allNotifications, { data: updatedNotifications })
    );
  };

  const handelLogOut = async () => {
    let deviceId = null;
    try {
      deviceId = await requestForToken();
    } catch {}
    let request = getRequestForApi(
      global.USER_LOGOUT,
      { deviceId },
      methodType.POST
    );

    await callHttpRequest(request).then((response) => {
      if (response?.status === 200 || response?.status === 201) {
        localStorage.clear();
        sessionStorage.clear();
        socket?.disconnect();
        localStorage.setItem("selectedLanguage", "en-US");
        i18n.changeLanguage("en-US");
        navigate("/");
      }
    });
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const isAuthenticated =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);

  React.useEffect(() => {
    if (isAuthenticated) {
      getUserProfile();
    } else {
      navigate("/");
    }
  }, [isAuthenticated]);

  const receiveListener = (msg) => {
    console.log("<Sidebar> receiveListener", msg);
    setNewMsg(msg);
    try {
      const ad = new Audio("/beep.mp3");
      ad.play();
    } catch (err) {
      console.log(err);
    }
  };

  //receiveMessage
  React.useEffect(() => {
    if (!socket) return;
    if (!socket.connected) socket.connect();
    console.log("<Sidebar> Socket ", socket);
    socket?.on("receiveMessage", receiveListener);
    socket?.on("REFRESH", updateTickets);
    return () => {
      socket?.off("receiveMessage", receiveListener);
    };
  }, [socket]);

  const getUserProfile = async () => {
    let requset, variables;
    requset = getRequestForApi(global.USER_PROFILE, variables, methodType.GET);
    await callHttpRequest(requset)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          const getData = response?.data;
          // console.log("getData");
          StorageHandling.setStorageData(
            StorageHandling.storageType,
            StorageHandling.storageKey.USERDATA,
            getData?.data?.doc.address?.country
          );
          dispatch(action(constants.UserData, getData));
        }
      })
      .catch((err) => {});
  };

  const getTotalTicketCount = async () => {
    let request;
    request = getRequestForApi(global.GET_ALL_TICKET_COUNT, methodType.GET);
    await callHttpRequest(request)
      .then(({ data }) => {
        dispatch(action(constants.allTicketCount, data));
        setPending(true);
        // setTicketCount(data);
      })
      .catch(({ res }) => {});
    // .finally(() => setPending(false));
  };

  const getNotification = async () => {
    setNotificationLoader(true);
    let request;
    request = getRequestForApi(global.GET_NOTIFICATION, methodType.GET);
    await callHttpRequest(request)
      .then(({ data }) => {
        // console.log("<Sidebar> All notifications", data);

        dispatch(action(constants.allNotifications, data));
        // setPending(true);
        // setTicketCount(data);
      })
      .catch(({ res }) => {})
      .finally(() => setNotificationLoader(false));
  };

  React.useEffect(() => {
    getTotalTicketCount();
  }, [UserData]);

  React.useEffect(() => {
    getNotification();
  }, [isAuthenticated, message]);

  const updateTickets = async () => {
    // if (socket) if (!socket?.connected) socket?.connect();

    // setTickets((prev) => ({ ...prev, isLoading: true }));
    // setPending(true);
    let request;
    request = getRequestForApi(
      global.GET_ALL_ROOMS +
        `?filter=approve&limit=${ticketCount?.approveTicket || 100}`,
      methodType.GET
    );
    await callHttpRequest(request)
      .then(({ data }) => {
        // setTickets(data);
        setTickets({ data: data.data, isLoading: false });
        let inTickets = false;

        data.data.forEach((item) => {
          let user = { room: item.tickets[0]._id };

          socket?.emit("join", user);
          // console.log("<AllTickets> User Joined");
        });
      })
      .catch(({ res }) => {
        // setTickets((prev) => ({ ...prev, isLoading: false }));
        // setPending(false);
      })
      .finally(() => {
        // setPending(false);
        setLoader(false);
      });
  };

  React.useEffect(() => {
    if (pending) {
      updateTickets();
    }
    if (chats?.data) {
      setPending(true);
    }
  }, [pending, chats?.data]);

  React.useEffect(() => {
    console.log("<Sidebar> tickets.data", tickets.data);
    const unReadMessageArray = [];
    if (tickets.data) {
      let count = 0;
      tickets?.data.map((item) => {
        count = count + item?.unmarkmsgcount;
        if (item?.unmarkmsgcount > 0) {
          for (let i = 0; i < item?.unmarkmsgcount; i++) {
            unReadMessageArray.push(item?.ticket);
          }
        }
      });
      setTotalMessageCount(count);
      if (unreadMessageCount) {
        setIsUnreadMsgs((prevMsg) => {
          return [...prevMsg, ...unReadMessageArray];
        });
        console.log("<Sidebar> isUnreadMsgs", isUnreadMsgs?.length);
        setunreadMessageCount(false);
      }
    }
  }, [tickets?.data]);

  React.useEffect(() => {
    if (!newMsg) return;
    if (refresh && ticket === newMsg?.room) {
      socket?.emit("mark", ticket);
      console.log("<Sidebar> if", refresh);
    } else {
      console.log("<Sidebar> else ", ticket, newMsg?.room);
      setTickets((prev) => ({
        ...prev,
        data: (tickets.data || []).map((room) => {
          if (room.tickets[0]._id === newMsg?.room) {
            room.recentmsg = {
              message: newMsg.message,
              createdAt: newMsg.createdAt,
              user: newMsg.user._id,
            };
            room.resent_msg_by = [newMsg.user];
            room.unmarkmsgcount = (room.unmarkmsgcount || 0) + 1;
          }
          return room;
        }),
      }));
    }
    updateTickets();
  }, [newMsg, socket, refresh]);
  const handleClearAll = (event) => {
    setLoader(true);

    if (tickets?.data) {
      tickets.data
        .filter((item) => item?.unmarkmsgcount > 0)
        .forEach((item) => {
          const id = item?.ticket;
          if (id) {
            socket.emit("mark", id);
          }
        });
      setIsUnreadMsgs([]);
    }
    setTimeout(() => {
      updateTickets();
    }, 3000);
  };

  const baseUrl = `${global.BASE_URL}/media/users/`;
  const fileName = UserData?.data?.doc?.photo;
  const imageUrl = baseUrl + fileName;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const MENU_DATA = [
    {
      id: 1,
      name: t("Dashboard.components.dashboard"),
      url: "/dashboard",
      icon: SVG.Dashboard,
    },
    {
      id: 2,
      name: t("Ticket_allTicket.tickets_title"),
      url: `/ticket`,
      icon: SVG.Ticket,
    },
    {
      id: 3,
      name: t("Billboard_main.Billboards.title"),
      url: "/billboard",
      icon: SVG.Billboard,
    },
    {
      id: 4,
      name: t("Ad_index.advertisements.title"),
      url: "/advertisement",
      icon: SVG.Ads,
    },
  ];

  const drawer = (
    <Box sx={{ background: "rgb(31 41 55 / 60%)", height: "100%", p: 3 }}>
      <Box sx={{ textAlign: "center", mb: 3 }}>
        <a href="#" onClick={() => window.location.reload()}>
          <SVG.SidebarLogo />{" "}
        </a>
      </Box>
      <ul className="menu">
        {MENU_DATA.map((item, index) => (
          <li key={index}>
            <Link
              to={item.url}
              className={
                location.pathname.includes(item.url) ? "active_menu" : ""
              }
              onClick={mobileOpen ? handleDrawerClose : null}
            >
              <item.icon /> {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </Box>
  );
  return (
    <>
      <Box
        sx={{ display: "flex" }}
        dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
      >
        <AppBar
          elevation={0}
          position="fixed"
          sx={{
            left: localStorage.getItem("i18nextLng") === "ar" ? "0" : "auto",
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: {
              sm:
                localStorage.getItem("i18nextLng") === "ar"
                  ? "0"
                  : `${drawerWidth}px`,
            },
            mr: localStorage.getItem("i18nextLng") === "ar" ? "280px" : "0px",
            backgroundImage: `url(${Topbar})`,
            backgroundSize: "cover",
            backgroundPosition: "center top",
            borderBottom: "1px solid #493932",
            paddingRight: "0px !important",
          }}
        >
          <Toolbar sx={{ p: 2 }}>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: { xs: 1, lg: 2 }, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton> */}
            <Box sx={{ display: { sm: "none" } }}>
              <Link to="#" onClick={() => window.location.reload()}>
                <img alt="" src={logo} style={{ width: "35px" }} />
              </Link>
            </Box>

            {matches ? null : <Language border={"0px"} bgColor="#493932" />}

            <Box
              sx={{
                marginLeft:
                  localStorage.getItem("i18nextLng") === "ar" ? "0px" : "auto",
                marginRight:
                  localStorage.getItem("i18nextLng") === "ar" ? "auto" : "0",
              }}
            >
              <Stack
                direction={"row"}
                spacing={{ xs: 1, lg: 2 }}
                alignItems={"center"}
              >
                {matches ? (
                  <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => {
                      // console.log("<Sidebar> popupState", popupState);
                      return (
                        <div>
                          <IconButton
                            disableRipple
                            {...bindTrigger(popupState)}
                          >
                            <LanguageIcon sx={{ color: "#fff" }} />
                          </IconButton>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Language
                              border={"0px"}
                              bgColor="#493932"
                              popupState={popupState}
                            />
                          </Popover>
                        </div>
                      );
                    }}
                  </PopupState>
                ) : null}
                <IconButton onClick={handleEmailMessage}>
                  <Badge
                    badgeContent={isUnreadMsgs?.length}
                    // variant={totalMessageCount > 0 ? "dot" : "standard"}+
                    sx={{
                      "& .MuiBadge-dot": {
                        background: "#E03137",
                        border: "2px solid #352614",
                        borderRadius: "100%",
                      },
                    }}
                    color="info"
                  >
                    <SVG.Email />
                  </Badge>
                </IconButton>
                <Popover
                  id={id}
                  open={open2}
                  anchorEl={emailMessage}
                  onClose={handleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      padding: "0px",
                      background: "#fff",
                      width: "450px",
                      borderRadius: "16px",
                      mt: 3,
                    },
                  }}
                >
                  <EmailMessage
                    handleClose={handleClose}
                    handleClearAll={handleClearAll}
                    tickets={tickets}
                    loader={loader}
                  />
                </Popover>

                <IconButton onClick={handleMessage}>
                  <Badge
                    variant={
                      Notifications?.data?.notifications?.length > 0
                        ? "dot"
                        : "standard"
                    }
                    sx={{
                      "& .MuiBadge-dot": {
                        background: "#E03137",
                        border: "2px solid #352614",
                        borderRadius: "100%",
                      },
                    }}
                  >
                    <SVG.Bell />
                  </Badge>
                </IconButton>

                <Popover
                  id={id}
                  open={open3}
                  anchorEl={message}
                  onClose={handleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      padding: "0px",
                      background: "#fff",
                      width: "450px",
                      borderRadius: "16px",
                      mt: 3,
                    },
                  }}
                >
                  <Notification
                    loader={notificationLoader}
                    handleClose={handleClose}
                    Notifications={Notifications}
                    handleCloseNotification={handleCloseNotification}
                    handleClearAllNotification={handleClearAllNotification}
                    handleReadNotification={handleReadNotification}
                  />
                </Popover>
                <Box
                  onClick={handleClick}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    "@media(max-width:768px)": {
                      gap: "5px",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      "@media(max-width:768px)": {
                        width: "30px",
                        height: "30px",
                      },
                    }}
                    alt={UserData?.data?.doc?.firstname || ""}
                    src={imageUrl}
                  />
                  <SVG.Arrow />
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      padding: "16px",
                      background: "#494130",
                      minWidth: "300px",
                      borderRadius: "16px",
                      mt: 3,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "& .MuiMenuItem-root": {
                        borderRadius: "10px",

                        color: "#fff",
                        fontSize: "14px",
                        gap: "10px",
                        padding: "16px",
                        fontWeight: "600",
                        "&:hover": { background: "#322E27" },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/profile"
                  >
                    <SVG.User />{" "}
                    <span> {t("Dashboard.components.profile")}</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handelLogOut();
                    }}
                  >
                    <SVG.Logout />{" "}
                    <span> {t("Dashboard.components.logout")}</span>
                  </MenuItem>
                </Menu>
              </Stack>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            anchor={
              localStorage.getItem("i18nextLng") === "ar" ? "right" : "left"
            }
            container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundImage: `url(${Background})`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
              },
            }}
          >
            {drawer}
            {matches ? (
              <Box
                sx={{
                  position: "fixed",
                  left:
                    localStorage.getItem("i18nextLng") === "ar" ? null : "24px",
                  right:
                    localStorage.getItem("i18nextLng") === "ar" ? "24px" : null,
                  bottom: "24px",
                }}
              >
                <Language border={"0px"} bgColor="#493932" />
              </Box>
            ) : (
              ""
            )}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundImage: `url(${Background})`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
                borderRight: "1px solid #493932",
                left:
                  localStorage.getItem("i18nextLng") === "ar" ? "auto" : "0px",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: { xs: "10px", lg: 3 },
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            background: "#160B01",
            minHeight: "100vh",
            overflow: "hidden",
            height:
              isPlatform("ios") || isPlatform("android") ? "100vh" : "auto",
            overflowY:
              isPlatform("ios") || isPlatform("android") ? "auto" : "hidden",
          }}
        >
          <Toolbar />
          <Box sx={{ py: 3, pb: matches && "85px" }}>
            <Outlet />
          </Box>
        </Box>
        {matches ? (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              borderTop: "1px solid #ccc",
              backgroundImage: `url(${Topbar})`,
              backgroundSize: "cover",
              backgroundPosition: "center top",
              p: 1,
              "& .menu": {
                flexDirection: "row",
                justifyContent: "space-between",
                "& li a": {
                  padding: "12px",
                  gap: "1px",
                  flexDirection: "column",
                  fontSize: "11px",
                },
              },
            }}
          >
            <ul className="menu">
              {MENU_DATA.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.url}
                    className={
                      location.pathname.includes(item.url) ? "active_menu" : ""
                    }
                    onClick={mobileOpen ? handleDrawerClose : null}
                  >
                    <item.icon />
                    <Box>{item.name}</Box>
                  </Link>
                </li>
              ))}
            </ul>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
